


























































































































import Vue from 'vue';
import { TenantObservable } from '../../libs/tenant';

export default Vue.extend({
  name: 'authentication',
  data() {
    return {
      isMounted: false,
      tenant: TenantObservable,
    };
  },
});
